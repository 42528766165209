import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Scrollspy from 'react-scrollspy'

export const Nav = styled.nav`
  margin-top: auto;
  a.isCurrent {
    text-decoration: underline;
    text-underline-offset: 5px;
  }
  @media ${(props) => props.theme.breakpoints.lg} {
    width: 50%;
    margin-top: unset;
  }
`

export const SpyScroll = styled((props) => (
  <Scrollspy
    className='scrollspy'
    items={['home', 'about', 'projects']}
    currentClassName='isCurrent'
    {...props}
  />
))`
  a {
    color: rgb(${(props) => props.theme.global.color});
  }
  padding: 0;
  margin: 0;

  @media ${(props) => props.theme.breakpoints.lg} {
    display: flex;
    justify-content: space-evenly;
  }
`

// Navigation Links
export const NavLink = styled((props) => <Link {...props} />)`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: right;
  padding: 2rem 0;
  font-weight: bold;
  letter-spacing: 0.5rem;
  color: rgb(${(props) => props.theme.global.linkActive});
  text-decoration: none;
  transition: color 0.3s linear;
  &:hover {
    color: ${(props) => props.theme.global.linkHover};
  }
  @media ${(props) => props.theme.breakpoints.lg} {
    padding: 0.5rem 0;
    font-size: 1.2rem;
  }
`
