import React from 'react'
import { bool } from 'prop-types'
import { StyledMenu, Toggler } from './Menu.styled'
import { MdDarkMode, MdLightMode } from 'react-icons/md'
import { useStyledDarkMode } from '../../../styles/useStyledDarkMode'
import Navbar from '../Nav/Navbar'

const Menu = ({ open }) => {
  const { isDark, toggleDark } = useStyledDarkMode()
  return (
    <StyledMenu open={open}>
      <Navbar />
      <Toggler onClick={() => toggleDark()}>
        {!isDark ? <MdDarkMode size='2em' /> : <MdLightMode size='2em' />}
      </Toggler>
    </StyledMenu>
  )
}
Menu.propTypes = {
  open: bool.isRequired,
}
export default Menu
