import React, { useState, useRef, useEffect } from 'react'
import { useOnClickOutside } from '../../hooks/MenuHook'
import Burger from './Burger/Burger'
import Menu from './Menu/Menu'
import Navbar from './Nav/Navbar'
import { Toggler } from './Menu/Menu.styled'
import { useStyledDarkMode } from '../../styles/useStyledDarkMode'

import { MdDarkMode, MdLightMode } from 'react-icons/md'
import { Container, FlexContainer } from './HeaderStyles'
import {Link} from "gatsby"

const Header = () => {
  const [open, setOpen] = useState(false)
  const [largeScreenSize, setLargeScreenSize] = useState('')
  const node = useRef()
  const { isDark, toggleDark } = useStyledDarkMode()
  useOnClickOutside(node, () => setOpen(false))

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let match = window.matchMedia('(min-width: 1024px)').matches
      setLargeScreenSize(match)
    }

    if (typeof window !== 'undefined') {
      window
        .matchMedia('(min-width: 1024px)')
        .addEventListener('change', (e) => {
          setLargeScreenSize(e.matches)
        })
    }
  }, [])

  return (
    <Container ref={node}>
      <FlexContainer>
        {!largeScreenSize ? (
          <>
            <Link to='/#home'>Home/Cornelius</Link>
            <Burger open={open} setOpen={setOpen} />
            <Menu open={open} setOpen={setOpen} />
          </>
        ) : (
          <>
            <Navbar />
            <Toggler onClick={() => toggleDark()}>
              {!isDark ? <MdDarkMode size='2em' /> : <MdLightMode size='2em' />}
            </Toggler>
          </>
        )}
      </FlexContainer>
    </Container>
  )
}

export default Header
