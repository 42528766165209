import React, { createContext, useState, useEffect } from "react"

export const DarkThemeKey = "theme"

export const ThemeSetting = {
  LIGHT: "light",
  DARK: "dark",
  SYSTEM: "system",
}

const defaultState = {
  isDark: undefined,
  didLoad: false,
  themeSetting: "system",
  toggleDark: () => undefined,
  changeThemeSetting: () => undefined,
}

export const ThemeManagerContext = createContext(defaultState)

const systemDarkModeSetting = () =>
  window.matchMedia
    ? window.matchMedia("(prefers-color-scheme: dark)")
    : window.matchMedia("(prefers-color-scheme: dark)")

const isDarkModeActive = () => {
  return !!systemDarkModeSetting()?.matches
}

export const ThemeManagerProvider = props => {
  const [themeSetting, setThemeSetting] = useState("system")
  const [didLoad, setDidLoad] = useState(false)
  const [isDark, setIsDark] = useState()

  useEffect(() => {
    const root = window.document.documentElement
    const initialColorValue = root.style.getPropertyValue(
      "--initial-color-mode"
    )
    setIsDark(initialColorValue === "dark")
    setDidLoad(true)
  }, [])

  const toggleDark = value => {
    const newIsDark = value ?? !isDark
    const theme = newIsDark ? "dark" : "light"
    setIsDark(newIsDark)
    setThemeSetting(theme)
    localStorage.setItem(DarkThemeKey, theme)
  }

  const changeThemeSetting = setting => {
    switch (setting) {
      case "system": {
        setIsDark(isDarkModeActive())
        break
      }
      case "light":
      case "dark":
        setIsDark(setting === "dark")
        break
      default: {
        setIsDark(setting === "dark")
      }
    }
    setThemeSetting(setting)
    localStorage.setItem(DarkThemeKey, setting)
  }

  return (
    <ThemeManagerContext.Provider
      value={{
        isDark,
        toggleDark,
        themeSetting,
        changeThemeSetting,
        didLoad,
      }}
    >
      {props.children}
    </ThemeManagerContext.Provider>
  )
}
