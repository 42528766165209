import styled from 'styled-components'
import { IoIosArrowDropdown } from 'react-icons/io'

export const Container = styled.header`
  z-index: 999999;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 4rem;
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1em;
  height: 4rem;
  background-color: rgb(${(props) => props.theme.global.bg});
  box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.15);
  a {
    color: rgb(${(props) => props.theme.global.color});
    font-weight: 800;
  }
  @media ${(props) => props.theme.breakpoints.lg} {
    justify-content: center;
    button {
      justify-content: flex-end;
      align-items: flex-end;
      margin: unset;
    }
  }
`

/// DropDown Contact
export const ContactDropDown = styled.button`
  border: none;
  display: flex;
  position: relative;
  background: none;
  font-size: 1.7rem;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  transition: 0.3s ease;
  &:focus {
    outline: none;
  }
  &:hover {
    color: #fff;
  }
`
export const NavProductsIcon = styled(IoIosArrowDropdown)`
  margin-left: 8px;
  display: flex;
  align-self: center;
  transition: 0.3s ease;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '.75')};
  transform: ${({ isOpen }) => (isOpen ? 'scaleY(-1)' : 'scaleY(1)')};
  &:hover {
    opacity: 1;
  }
`
