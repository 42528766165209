import React from 'react'
import { StyledThemeProvider } from './src/styles/styledThemeProvider'
import { ThemeManagerProvider } from './src/styles/themeManager'
import { darkTheme, lightTheme, GlobalStyles } from './src/styles/GlobalStyles'
import Layout from './src/Layout/Layout'

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeManagerProvider>
      <StyledThemeProvider lightTheme={lightTheme} darkTheme={darkTheme}>
        {element}
      </StyledThemeProvider>
    </ThemeManagerProvider>
  )
}

export const wrapPageElement = ({ element, props }) => (
  <>
    <GlobalStyles />
    <Layout {...props}>{element}</Layout>
  </>
)
