import styled from 'styled-components'
import React from 'react'
import { Link } from 'gatsby'

export const FooterWrapper = styled.footer`
  min-height: 9rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(${(props) => props.theme.global.footer});
  max-width: 100%;
`

export const FooterContainer = styled.div`
  padding: 1em 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(${(props) => props.theme.global.footer});
  h4 {
    margin-bottom: 0.5rem;
  }
`

export const FooterLinks = styled((props) => <Link {...props} />)`
  color: rgb(${(props) => props.theme.global.link});
  margin: 0.1em 0;
  border-bottom: 1px dotted rgb(${(props) => props.theme.global.link});
  &:hover {
    border-bottom: 1px solid rgb(${(props) => props.theme.global.link});
  }
`

export const CarbonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 1em 1.5rem;
`

export const CopyrightContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgb(${(props) => props.theme.global.copyright});
  font-size: 0.9em;
  min-height: 2em;
  span {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    svg {
      margin-right: 0.2rem;
      font-size: 0.8rem;
    }
    p {
      margin: 0;
    }
  }
`
