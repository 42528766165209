import React from 'react'
import { NavLink, Nav, SpyScroll } from './Navbar.styles'

const Navbar = () => {
  return (
    <Nav>
      <SpyScroll>
        <NavLink to='/#home'>Home</NavLink>
        <NavLink to='/#about'>About</NavLink>
        <NavLink to='/#projects'>Projects</NavLink>
      </SpyScroll>
    </Nav>
  )
}

export default Navbar
