import { createGlobalStyle } from 'styled-components'
import normalize from 'styled-normalize'

const colorPalette = {
  lightShades: '242, 242, 242',
  lightAccent: '255, 255, 255',
  lightGradientLightBlue: '85, 185, 242',
  lightGradientDarkBlue: '36, 143, 204',
  lightFooter: '230, 230, 230',
  lightCopyright: '210, 210, 210',
  mainBrand: '140, 100, 88',
  darkShades: '32, 32, 32',
  darkAccent: '133, 129, 137',
  darkGradientDarkBlue: '2, 51, 108',
  darkGradientDarkerBlue: '39, 65, 95',
  darkFooter: '20, 20, 20',
  darkCopyright: '0, 0, 0',
  success: '95, 153, 81',
  warning: '221, 136, 25',
  error: '244, 67, 54',
}

const baseTheme = {
  actions: {
    error: colorPalette.error,
    info: colorPalette.darkShades,
    primary: colorPalette.mainBrand,
    success: colorPalette.success,
    warning: colorPalette.warning,
  },
  palette: {
    darkAccent: colorPalette.darkAccent,
    darkShades: colorPalette.darkShades,
    lightAccent: colorPalette.lightAccent,
    lightShades: colorPalette.lightShades,
    mainBrand: colorPalette.mainBrand,
  },
  // Temp fonts
  fonts: {
    title: 'Space Grotesk, sans-serif',
    main: 'Space Grotesk, sans-serif',
  },
  // Breakpoints for responsive design
  breakpoints: {
    sm: 'screen and (min-width: 640px)',
    md: 'screen and (min-width: 768px)',
    lg: 'screen and (min-width: 1024px)',
    xl: 'screen and (min-width: 1280px)',
  },
}

export const darkTheme = {
  ...baseTheme,
  global: {
    bg: colorPalette.darkShades,
    color: colorPalette.lightShades,
    lightGradient: colorPalette.darkGradientDarkBlue,
    darkGradient: colorPalette.darkGradientDarkerBlue,
    link: colorPalette.lightShades,
    linkActive: colorPalette.lightShades,
    linkInactive: colorPalette.lightAccent,
    footer: colorPalette.darkFooter,
    copyright: colorPalette.darkCopyright,
  },
}

export const lightTheme = {
  ...baseTheme,
  global: {
    bg: colorPalette.lightAccent,
    color: colorPalette.darkShades,
    lightGradient: colorPalette.lightGradientLightBlue,
    darkGradient: colorPalette.lightGradientDarkBlue,
    link: colorPalette.darkShades,
    linkActive: colorPalette.darkShades,
    linkInactive: colorPalette.darkAccent,
    footer: colorPalette.lightFooter,
    copyright: colorPalette.lightCopyright,
  },
}

export const GlobalStyles = createGlobalStyle`
  ${normalize};

  html {
    scroll-behavior: smooth;
  }

  html, body {
    margin: 0;
    padding: 0; 
  }

  h1,h2,h3,h4,h5{
    margin: 0;
  }

  *, *::after, *::before {
      box-sizing: border-box;
    }

  body {
    font-size: 1.2rem;
    background: linear-gradient(0deg, rgb(${(props) =>
      props.theme.global.bg}), rgb(${(props) =>
  props.theme.global.lightGradient}), rgb(${(props) =>
  props.theme.global.darkGradient}));
    background-size: 600% 600%;
    color: rgb(${(props) => props.theme.global.color});
    cursor: default;
    max-width: 100%;
    max-height: 100%;
    font-family: 'Loto', sans-serif;
    -webkit-animation: AnimationName 120s ease infinite;
    -moz-animation: AnimationName 120s ease infinite;
    -o-animation: AnimationName 120s ease infinite;
    animation: AnimationName 120s ease infinite;
  }

  @-webkit-keyframes AnimationName {
    0%{background-position:50% 0%}
    50%{background-position:51% 100%}
    100%{background-position:50% 0%}
  }
  @-moz-keyframes AnimationName {
      0%{background-position:50% 0%}
      50%{background-position:51% 100%}
      100%{background-position:50% 0%}
  }
  @-o-keyframes AnimationName {
      0%{background-position:50% 0%}
      50%{background-position:51% 100%}
      100%{background-position:50% 0%}
  }
  @keyframes AnimationName {
      0%{background-position:50% 0%}
      50%{background-position:51% 100%}
      100%{background-position:50% 0%}
  }

  a {
    text-decoration: none;
  }

  li{
    list-style: none;
  }

`
