import styled from 'styled-components'

export { default } from './Menu'

export const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgb(${(props) => props.theme.global.bg});
  transform: ${({ open }) =>
    open ? 'translate3d(0, 0,0)' : 'translate3d(100%, 0, 0)'};
  height: 100%;
  text-align: right;
  padding: 1.5rem;
  position: fixed;
  top: 0;
  right: 0;
  transition: all 0.5s;
  overflow: hidden;
  width: auto;

  @media ${(props) => props.theme.breakpoints.lg} {
    display: none;
  }
`
export const Link = styled.a`
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: right;
  padding: 2rem 0;
  font-weight: bold;
  letter-spacing: 0.5rem;
  text-decoration: none;
  transition: color 0.3s linear;

  &:hover {
    color: ${(props) => props.theme.global.linkHover};
  }
`

export const Toggler = styled.button`
  color: rgb(${(props) => props.theme.global.color});
  background-color: transparent;
  transition: all ${(props) => props.theme.transitionTime};
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: auto;

  @media ${(props) => props.theme.breakpoints.lg} {
    font-size: 1.1rem;

  }
`
