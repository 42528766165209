import React from 'react'
import {
  CopyrightContainer,
  FooterLinks,
  FooterWrapper,
  FooterContainer,
  CarbonContainer,
} from './Footer.styled'
import { AiOutlineCopyright } from 'react-icons/ai'
import Carbonbadge from './Carbonbadge/Carbonbadge'
import { useStyledDarkMode } from '../../styles/useStyledDarkMode'

const Footer = () => {
  const { isDark } = useStyledDarkMode()
  return (
    <FooterWrapper>
      <FooterContainer>
        <h4>Site map</h4>
        <FooterLinks to='/#home'>Home</FooterLinks>
        <FooterLinks to='/#about'>About</FooterLinks>
        <FooterLinks to='/#projects'>Projects</FooterLinks>
      </FooterContainer>
      <CarbonContainer>
        <Carbonbadge darkMode={isDark ? true : false} />
        <img
          src='https://api.thegreenwebfoundation.org/greencheckimage/sandmal.com?nocache=true'
          alt='This website is hosted Green - checked by thegreenwebfoundation.org'
        />
      </CarbonContainer>
      <CopyrightContainer>
        <span>
          <AiOutlineCopyright />
          <p>2022 Cornelius Ottar Sandmal</p>
        </span>
      </CopyrightContainer>
    </FooterWrapper>
  )
}

export default Footer
