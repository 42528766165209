import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { LayoutStyles } from './LayoutStyles'
import Seo from '../components/Seo/Seo'

const Layout = ({ children }) => {
  return (
    <LayoutStyles>
      <Seo />
      <Header />
      <main>{children}</main>
      <Footer />
    </LayoutStyles>
  )
}

export default Layout
